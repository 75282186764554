import { CommonModule } from "@angular/common";
import {
  Component,
  computed,
  DestroyRef,
  inject,
  OnInit,
  signal,
} from "@angular/core";
import { takeUntilDestroyed, toSignal } from "@angular/core/rxjs-interop";
import { FormBuilder, ReactiveFormsModule, Validators } from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from "@angular/material/dialog";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { UserConfigStore } from "@app/core/user-config/+data-access";
import {
  ArcRequestModel,
  ArcRequestReqBody,
  ArcRequestService,
} from "@app/pages/architectural/+data-access";
import { ModificationDataModel } from "@app/pages/modifications/utils/model";
import { PropertyModel } from "@app/pages/property/+data-access";
import {
  AttachmentModel,
  RoamToastrService,
} from "@app/pages/task/+data-access";
import { ButtonModule } from "@app/shared/components/button/button.module";
import { FileUploaderComponent } from "@app/shared/components/file-uploader";
import { RoamDatepickerComponent } from "@app/shared/components/roam-datepicker/roam-datepicker.component";
import { RoamInputComponent } from "@app/shared/components/roam-input/roam-input.component";
import { RoamRadioCardComponent } from "@app/shared/components/roam-radio-card/roam-radio-card.component";
import { RoamSelectComponent } from "@app/shared/components/roam-select/roam-select.component";
import { RoamTextAreaComponent } from "@app/shared/components/roam-text-area/roam-text-area.component";
import { SharingEmailComponent } from "@app/shared/components/sharing-email/sharing-email.component";
import { ICustomer } from "@app/shared/interfaces/customer.interface";
import { IRadio } from "@app/shared/interfaces/roam-radio.inteface";
import { AssociationService } from "@app/shared/services/association.service";
import { ModificationService } from "@app/shared/services/modification.service";
import { finalize, merge, tap } from "rxjs";
import { CustomerService } from "./../../../../shared/services/customer.service";

@Component({
  selector: "app-add-new-modification",
  standalone: true,
  imports: [
    ButtonModule,
    MatDialogModule,
    ReactiveFormsModule,
    RoamInputComponent,
    RoamSelectComponent,
    RoamDatepickerComponent,
    SharingEmailComponent,
    FileUploaderComponent,
    RoamRadioCardComponent,
    RoamTextAreaComponent,
    MatProgressSpinnerModule,
    CommonModule,
  ],
  templateUrl: "./add-new-modification.component.html",
  styles: `
    :host {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .title-div {
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  `,
})
export class AddNewModificationComponent implements OnInit {
  #destroyRef = inject(DestroyRef);
  ref = inject(MatDialogRef);
  readonly dialog = inject(MatDialog);
  readonly today = new Date();
  readonly dialogData = inject(MAT_DIALOG_DATA);
  readonly fb = inject(FormBuilder);
  private associationService = inject(AssociationService);
  private modificationService = inject(ModificationService);
  private arcRequestService = inject(ArcRequestService);
  private userConfig = inject(UserConfigStore);
  private customerService = inject(CustomerService);
  private toastr = inject(RoamToastrService);
  protected propertyId = signal<string>("");
  protected attachments = signal<AttachmentModel[]>([]);
  protected modelConfig = computed(() => {
    return {
      propertyId: this.propertyId(),
      modelId: this.info?.id || crypto.randomUUID(),
      model: "modification",
    };
  });

  readonly loaders = {
    typesLoading: signal(false),
    customersLoading: signal(false),
    propertiesLoading: signal(false),
    formSubmitting: signal(false),
  } as const;

  readonly opts = {
    properties: signal<PropertyModel[]>([]),
    customers: signal<ICustomer[]>([]),
    moderateTypes: signal<IRadio[]>([]),
    majorTypes: signal<IRadio[]>([]),
    minorTypes: signal<IRadio[]>([]),
  } as const;

  readonly form = this.fb.group({
    propertyId: ["", Validators.required],
    customerId: ["", Validators.required],
    date: [this.today.toISOString(), Validators.required],
    startDate: [this.today.toISOString(), Validators.required],
    completionDate: [this.today.toISOString(), Validators.required],
    categoryId: [""],
    projectName: ["", Validators.required],
    description: ["", Validators.required],
  });

  get controls() {
    return this.form.controls;
  }

  get title() {
    return this.dialogData?.title || this.info ?
        "Edit Modification"
      : "Add Modification";
  }

  get info(): ArcRequestModel | null {
    return this.dialogData?.info || null;
  }

  private configRadio = (types: ModificationDataModel[]) => {
    return types.map(item => ({
      id: item.id,
      name: "modification",
      label: item.title,
      value: item.id,
      icon: item.icon,
    }));
  };

  submitCreate = (body: ArcRequestReqBody.CreateOne, close = false) => {
    this.loaders.formSubmitting.set(true);
    this.arcRequestService
      .createArcRequest(body)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe({
        error: () => {
          this.loaders.formSubmitting.set(false);
          this.toastr.danger("Failed to create new request!");
        },
        next: resp => {
          this.loaders.formSubmitting.set(false);
          this.toastr.success("Request created successfully!");
          close && this.ref.close(resp);
        },
      });
  };

  submitPatch = (
    id: string,
    body: ArcRequestReqBody.PatchOne,
    close = false
  ) => {
    this.loaders.formSubmitting.set(true);
    this.arcRequestService
      .patchArcRequest(id, body)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe({
        error: () => {
          this.loaders.formSubmitting.set(false);
          this.toastr.danger("Failed to update the request!");
        },
        next: resp => {
          this.loaders.formSubmitting.set(false);
          this.toastr.success("Request updated successfully!");
          close && this.ref.close(resp);
        },
      });
  };

  save(closeOnSuccess = false): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const existing = this.info as ArcRequestModel;
    const v = this.form.getRawValue();
    if (!existing) {
      this.submitCreate(
        {
          propertyId: v.propertyId || this.propertyId(),
          categoryId: v.categoryId || "",
          customerId: v.customerId || "",
          startDate: v.startDate || "",
          completionDate: v.completionDate || "",
          description: v.description || "",
          date: v.date || "",
        },
        closeOnSuccess
      );
    } else {
      this.submitPatch(
        existing.id,
        {
          propertyId: v.propertyId || this.propertyId(),
          categoryId: v.categoryId || "",
          customerId: v.customerId || "",
          description: v.description || "",
          startDate: v.startDate || "",
          completionDate: v.completionDate || "",
        },
        closeOnSuccess
      );
    }
  }

  protected initOptions = (): void => {
    this.loaders.propertiesLoading.set(true);
    const initProperties$ = this.associationService.getAssociationList().pipe(
      finalize(() => this.loaders.propertiesLoading.set(false)),
      tap(resp => {
        const items: PropertyModel[] = resp?.properties || [];
        this.opts.properties.set(items);
      })
    );

    this.loaders.customersLoading.set(true);
    const initCustomers$ = this.customerService
      .getAllCustomers(this.propertyId())
      .pipe(
        finalize(() => this.loaders.customersLoading.set(false)),
        tap(resp => {
          const items: ICustomer[] = resp?.data || [];
          this.opts.customers.set(items);
        })
      );

    this.loaders.typesLoading.set(true);
    const initTypes$ = this.modificationService.getCategories().pipe(
      finalize(() => this.loaders.typesLoading.set(false)),
      tap(resp => {
        this.opts.moderateTypes.set(this.configRadio(resp.moderate));
        this.opts.minorTypes.set(this.configRadio(resp.minor));
        this.opts.majorTypes.set(this.configRadio(resp.major));
      })
    );

    merge(initProperties$, initCustomers$, initTypes$)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe();
  };

  protected setupEditMode = (): void => {
    const v = this.info;
    console.log("DETAIL » ", v);
    if (!v) return;
    this.form.patchValue({
      date: v.date || "",
      categoryId: v.categoryId || "",
      customerId: v.customerId || "",
      startDate: v.startDate || "",
      completionDate: v.completionDate || "",
      projectName: v.description || "",
      description: "",
    });
    this.attachments.set(v.files || []);
  };

  ngOnInit(): void {
    this.initOptions();
    this.setupEditMode();
  }

  constructor() {
    const selectedAssociationId = toSignal(
      this.userConfig.selectedAssociationId$,
      { initialValue: "" }
    );
    const defaultSelected =
      this.info?.propertyId || selectedAssociationId() || "";
    this.propertyId.set(defaultSelected);
    this.form.controls.propertyId.patchValue(defaultSelected);
  }
}
